<template>
  <div class="staff">
    <intro title="Dental Care" :content="paragraphs" id="intro"/>
    <div class="pgcontent">
      <h2>Quick summary of the service we provide</h2>
        <card v-for="treatment in treatments" :key="treatment.name" :icon="treatment.icon" :title="treatment.name" :content="[treatment.type]" :button="treatment.button" :buttonLink="treatment.link" />
      </div>
      <div class="sections">
        <MoreInfo :img="true" v-for="section in sections" :image="section.image" :key="section.name" :heading="section.title" :content="section.type" :light="section.light" :seal="section.seal" :button="section.button" :link="section.link" />
      </div>
  </div>
</template>

<script>
import Intro from '../components/Intro.vue'
import Card from '../components/Card.vue'
import MoreInfo from '../components/MoreInfo'
import banner from '../assets/banner-2.jpg'
import banner1 from '../assets/banner.png'
export default {
  name: 'Staff',
  components: {
    Intro,
    Card,
    MoreInfo
  },
  data(){
    return{
      paragraphs: [
          "We offer a wide range of preventative and restorative dentistry, from examinations to hygienist treatment, short-term orthodontics, tooth whitening, aesthetic dentistry, oral surgery and implant treatment. You may find more information about these treatments below.",
          ""
      ],
      treatments: {
		'General': {
			'icon': 'bi-heart-fill',
			'name': 'General',
			'type': 'Complete care for your dental health including regular examinations and planning for extensive treatment.',
			'button': 'Read More ',
			'link': '/dentalcare/general'
		},
		'Restorative': {
			'icon': 'bi-plus',
			'name': 'Restorative',
			'type': 'A broad spectrum of treatment including fillings, crowns and bridges.',
			'button': 'Read More',
			'link': '/dentalcare/restorative'
		},
		'Dentures': {
			'icon': 'bi-activity',
			'name': 'Dentures',
			'type': 'Removable appliances that can comfortably replace missing teeth.',
			'button': 'Read More ',
      'link': '/dentalcare/dentures'
		},
		'Implants':{
			'icon': 'bi-stars',
			'name': 'Dental Implants',
			'type': 'A fixed option of a surgically placed biocompatible (titanium) abutment to replace missing teeth.',
			'button': 'Read More ',
      'link': '/dentalcare/dentalimplants'
		},
		'Orthodontics': {
			'icon': 'bi-upc',
			'name': 'Orthodontics',
			'type': 'Align and straighten minor imperfections on teeth to create a more even smile.',
			'button': 'Read More ',
      'link': '/dentalcare/orthodontics'
		},
		'Whitening': {
			'icon': 'bi-question-circle',
			'name': 'Whitening',
			'type': 'Minimally invasive treatment that can improve and brighten your smile.',
			'button': 'Read More ',
      'link': '/dentalcare/whitening'
		},
    'Oral Surgery': {
      'icon': 'bi-question-circle',
      'name': 'Oral Surgery (Extractions)',
      'type': 'Removal of teeth with a poor long-term prognosis which may be lost through natural causes, infection or extensive decay.',
      'button': 'Read More ',
      'link': '/dentalcare/oralsurgery'
    },
    'Root Canal Treatment': {
      'icon': 'bi-question-circle',
      'name': 'Root Canal Treatment',
      'type': 'Treatment of the infected nerve (dental pulp) to save a tooth.',
      'button': 'Read More ',
      'link': '/dentalcare/rootcanaltreatment'
    },
    'Periodontics & Hygiene': {
      'icon': 'bi-question-circle',
      'name': 'Periodontics & Hygiene',
      'type': 'Management and treatment of gum disease through regular cleaning and maintenance.',
      'button': 'Read More ',
      'link': '/dentalcare/periodonticsandhygiene'
    }
		},
		sections: {
		'children': {
			'title': 'Children',
			'seal': false,
			'type': [
			"We have a small commitment to the NHS for patients under the age of 18 (or up to 19 years old if in full-time education). We are able to treat children under the NHS, or privately.",
			"It is important that as soon as a child shows signs of cutting their first teeth, they should be registered with a dentist. The current recommendation is twice-yearly examinations for children. In some circumstances they may be seen on a 3-monthy basis. At each examination your dentist will give advice on how to maintain good oral health and dietary habits, key to preventing compromises to your child’s teeth in the future.",
			"Prevention treatment includes the application of fluoride varnish, fissure sealants and restorative work to look after your child’s teeth. They may also be referred to an orthodontic specialist if they need braces, and we are also able to provide sports gum shields to protect your child’s teeth."
			],
			'light': false,
			'button': 'Book an appointment',
      'link': 'contact',
      'image': banner,
		},
		'Nervous Patients': {
			'title': 'Nervous Patients',
			'seal': true,
			'type': [
				"Fear can come in all shapes and sizes; we understand that dental anxiety is no exception to this. You may be curious that half the population in the UK are reported to suffer from some degree of dental anxiety. At Broad Street Dental Practice, we pride ourselves on being able to help our patients with a personalised approach to those who suffer from dental anxiety, in order to overcome their concerns and worries, however big or small they are.",
				"We are a friendly and approachable team that will ensure that your concerns are heard. We realise that feelings are personal to each individual and do encourage them to be discussed, so we can do our best to accommodate you. There are various ways in which you can contact the practice to discuss this. If you wish to visit the practice before any appointments during a quieter period, let us know. We will do our best to make sure you feel looked after in welcoming and comfortable dental environment. "
			],
			'light': true,
			'button': 'Contact us to learn more',
      'link': 'contact',
      'image': banner1,
		}
		}
    }
  }
}
</script>

<style scoped>
  .pgcontent {
    background-color: #FFF;
  }
  h2{
	padding: 1rem 1.5rem;
	margin: 0;
	color: var(--primary)
  }
  @media screen and (min-width: 768px) {
	h2{
		padding: 1rem var(--padding1);		
	}
  }
  @media screen and (min-width: 1024px) {
	h2{
		padding: 1rem 0;
		width: 100%;
	}
    .pgcontent{
        display: flex;
        flex-wrap: wrap;
        padding: 2rem var(--padding1);
        justify-content: center;
    }
	.card:nth-last-child(2):nth-child(odd) {
		margin-right: auto;
	}

    
  }
  @media screen and (min-width: 1440px) {
    .pgcontent{
        padding: 2rem var(--padding2);
      background-color: var(--background);
    }   
  }
  @media screen and (min-width: 1750px){
    .pgcontent{
        padding: 2rem 4rem;
    }
    .card, .card:first-child, .card:last-child{
        margin: 1rem 2rem !important;
    }
  }
</style>